<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col>
        <div>
          <h2 class="overline mt-2 ml-4 grey--text d-flex">Campañas</h2>
        </div>
        <v-data-table
          dense
          multi-sort
          :options="{ itemsPerPage: 100 }"
          :headers="
            [
              { name: 'Nombre', value: 'name' },
              { name: 'Fecha de inicio', value: 'startDate' },
              { name: 'Fecha de finalización', value: 'endDate' },
            ].map((i) => {
              return { text: i.name, value: i.value };
            })
          "
          :items="processedAbstractCampaigns"
          @click:row="handleRowClick"
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex ml-4 mr-4 mt-4">
          <v-text-field
            class="ml-2"
            label="Nombre"
            type="text"
            dense
            v-model="newAbstractCampaign.name"
          >
          </v-text-field>

          <v-text-field
            dense
            class="ml-2"
            label="Inicio"
            type="date"
            v-model="newAbstractCampaign.startDate"
          >
          </v-text-field>

          <v-text-field
            dense
            class="ml-2"
            label="Fin"
            type="date"
            v-model="newAbstractCampaign.endDate"
          >
          </v-text-field>

          <v-btn
            color="primary white--text"
            class="ml-2"
            @click="saveNewAbstractCampaign"
            depressed
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import useSelectable from "../mixins/useSelectables";
import useSaveNewEngagement from "../mixins/useSaveNewEngagement";

import { postRequest } from "@/managrx/serverCall";

export default {
  //
  name: "AbstractCampaigns",
  mixins: [useSelectable, useSaveNewEngagement],
  computed: {
    ...mapGetters(["abstractCampaigns"]),
    campaignTableKeys() {
      return this.campaigns[0] ? Object.keys(this.campaigns[0]) : [];
    },
    processedAbstractCampaigns() {
      return this.abstractCampaigns.map((i) => {
        return {
          id: i.id,
          name: i.name,
          startDate: new Date(i.startDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          endDate: new Date(i.endDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
        };
      });
    },
  },

  mounted() {
    document.title = "Campañas";
    this.$store.dispatch("getAbstractCampaigns");
  },
  methods: {
    handleRowClick(e) {
      this.$router.push({
        path: `/abstract-campaign/${e.id}`,
      });
    },
    resetAbstractCampaignForm() {
      this.newAbstractCampaign = {
        name: null,
        startDate: null,
        endDate: null,
      };
    },
    async saveNewAbstractCampaign() {
      let payload = {
        name: this.newAbstractCampaign.name,
        startDate: new Date(this.newAbstractCampaign.startDate).toISOString(),
        endDate: new Date(this.newAbstractCampaign.endDate).toISOString(),
      };
      try {
        let response = await postRequest("/abstract_campaign", payload);
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          let newAbstractCampaignId = response.data;
          payload = {
            version: 0,
            abstractCampaignId: newAbstractCampaignId,
          };
          response = await postRequest("/campaign", payload);
          if (!response.success) {
            this.$store.dispatch("setNewNotification", response);
          } else {
            let campaignId = response?.data;
            response = await postRequest(
              `/module/post/add_new_campaign_prices/${campaignId}`
            );
            if (!response.success) {
              this.$store.dispatch("setNewNotification", response);
            } else {
              this.$store.dispatch("getAbstractCampaigns");
              this.resetAbstractCampaignForm();
            }
          }
        }
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
        console.log("Failed to create new Abstract Campaign", payload);
        console.log(error.response);
      }
    },
  },
  data() {
    return {
      newAbstractCampaign: {
        name: null,
        startDate: null,
        endDate: null,
      },
    };
  },
};
</script>
